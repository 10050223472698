<template>
    <div class="background">
        <div class="contain">
            <div class="header">
                <div class="topbar">
                    <div v-if="!isSingleOpen" class="right-bar">
                        <b-button size="sm" variant="dark">
                            <img src="/images/treasure-closed.png" width="12px" class="mr-1" />
                            {{ remainingChests }} chest(s) left
                        </b-button>
                        <b-button class="ml-2" size="sm" @click="openSummary">
                            Skip
                        </b-button>
                    </div>
                </div>
            </div>
            <div class="content d-flex flex-column justify-content-center" @click="switchTreasure">
                <div>
                    <img :class="treasureCss + ' image-box'" :src="treasureSrc" />
                </div>
                <div v-if="treasureCss == 'prep'">
                    <div class="small text-darkGrey mt-2">Click to open the treasure chest</div>
                </div>
                <div v-if="shouldShowResultText" class="mt-4">
                    <div v-if="activeChest.isWin == true">Congratulations!</div>
                    <h4 class="mb-0 mt-1">{{ activeChest.itemWon }}</h4>
                    <img v-if="activeChest.tier == 1" src="/images/gold.png" class="gold-sizing" />
                    <img v-else-if="activeChest.tier == 2" src="/images/silver.png" class="silver-sizing" />
                    <img v-else-if="activeChest.tier == 3" src="/images/bronze.png" class="bronze-sizing" />
                    <div v-else class="small">Better luck next time!</div>
                    <div class="small text-darkGrey mt-2">Click to continue</div>
                </div>
            </div>
        </div>
        <b-modal id="m-confirm-leave" hide-footer hide-header centered no-close-on-backdrop
            body-bg-variant="dark" body-text-variant="light"
        >
            <div class="text-center">
                Leave without seeing all rewards? You can look at your rewards again by accessing the History page!
            </div>
            <div class="mt-4 d-flex flex-row justify-content-between">
                <b-button class="ml-4" @click="$bvModal.hide('m-confirm-leave')">
                    Cancel
                </b-button>
                <b-button class="mr-4" variant="success" @click="confirmLeave">
                    Confirm
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'gacha-opening',
    props: {
        result: { type: Object, required: false }
    },
    data() {
        return {

            isSingleOpen: false,
            shouldRespondToClick: true,
            shouldShowResultText: false,
            shouldAllowBack: false,

            remainingChests: 0,
            rewards: [],
            aggregatedRewards: [],
            currentStep: 1,

            activeChest: {
                itemWon: "",
                tierName: "",
                imagePath: ""
            },

            treasureCss: 'prep', // 3 steps: prep (goyang2 sebelum dipencet), pop (animasi membuka chest, ketika sudah diklik), result (show hadiahnya)
            treasureSrc: '/images/treasure-closed.png',

            soundOpen: new Audio("/audio/chest_open.mp3"),
            soundZonk: new Audio("/audio/zonk.mp3"),
            soundBronze: new Audio("/audio/bronze.mp3"),
            soundSilver: new Audio("/audio/silver.mp3"),
            soundGold: new Audio("/audio/gold.mp3")

        }
    },
    methods: {

        switchTreasure() {

            if(!this.shouldRespondToClick) {
                return
            }

            if(this.treasureCss == "prep") {

                this.shouldRespondToClick = false
                this.switchTreasureCss()

                // start suara buka chest
                this.soundOpen.play()

                // animasi kotak chest tertutup goyang-goyang selesai, ganti foto menjadi kotak chest terbuka
                setTimeout(() => {
                    this.switchTreasureSrc()
                }, 750)

                // animasi buka chest selesai, show hadiah
                setTimeout(() => {

                    this.treasureSrc = this.activeChest.imagePath
                    this.treasureCss = "result"

                    if(this.activeChest.isWin == true) {

                        if(this.activeChest.tier == 1) {
                            this.soundGold.play()
                        }
                        else if(this.activeChest.tier == 2) {
                            this.soundSilver.play()
                        }
                        else if(this.activeChest.tier == 3) {
                            this.soundBronze.play()
                        }

                    }
                    else {
                        this.soundZonk.play()
                    }

                }, 1600)

                // Hadiah sudah selesai animasi, unlock click respond dan show result text
                setTimeout(() => {

                    this.shouldShowResultText = true
                    this.shouldRespondToClick = true

                    if(this.activeChest.isWin == true) {
                        
                        if(this.activeChest.tier == 1) {

                            this.$confetti.default({
                                angle: 60,
                                spread: 55,
                                origin: { x: 0 }
                            })

                            this.$confetti.default({
                                angle: 120,
                                spread: 55,
                                origin: { x: 1 }
                            })

                        }
                        else if(this.activeChest.tier == 2) {
                            this.$confetti.default()
                        }

                    }

                }, 2600)

            }
            else {

                // Chest habis, lanjutkan ke summary bila multi open
                if(this.remainingChests <= 1) {
                    
                    if(this.isSingleOpen == true) {
                        this.shouldAllowBack = true
                        return this.$router.back()
                    }
                    else {
                        return this.openSummary()
                    }
                    
                }

                this.shouldShowResultText = false
                this.switchTreasureCss()
                this.switchTreasureSrc()

                // Stop semua audio
                this.soundGold.pause()
                this.soundSilver.pause()
                this.soundBronze.pause()
                this.soundZonk.pause()

                this.soundGold.currentTime = 0
                this.soundSilver.currentTime = 0
                this.soundBronze.currentTime = 0
                this.soundZonk.currentTime = 0

                // Prepare next chest
                this.currentStep++
                this.remainingChests--

                // Cari chest yang slotnya adalah currentStep. return undefined bila tidak ada
                this.resolveNextChest()
                
            }

        },

        switchTreasureCss() {
            if(this.treasureCss == "prep") {
                this.treasureCss = "pop"
            }
            else {
                this.treasureCss = "prep"
            }
        },

        switchTreasureSrc() {
            if(this.treasureSrc == "/images/treasure-closed.png") {
                this.treasureSrc = "/images/treasure-open.png"
            }
            else {
                this.treasureSrc = "/images/treasure-closed.png"
            }
        },

        resolveNextChest() {

            let nextChest = this.rewards.find(it => it.slot == this.currentStep)

            if(nextChest == undefined || nextChest == null) {
                this.activeChest.isWin = false
                this.activeChest.itemWon = "Too bad!"
                this.activeChest.imagePath = "/images/zonk.png"
                this.activeChest.tier = null
            }
            else {
                this.activeChest.isWin = true
                this.activeChest.itemWon = nextChest.title
                this.activeChest.imagePath = nextChest.image_url
                this.activeChest.tier = nextChest.grade
            }

        },

        confirmLeave() {
            this.shouldAllowBack = true
            this.$router.go(-1)
        },

        openSummary() {
            this.$router.replace({
                name: "Gacha Result",
                params: {
                    rewards: this.aggregatedRewards
                }
            })
        }

    },
    created() {

        if(this.result == null || this.result == undefined) {
            return this.$router.replace({ path: '/' })
        }

        this.isSingleOpen = this.result.play_for == 1
        this.remainingChests = this.result.play_for
        this.rewards = this.result.rewards
        this.resolveNextChest()

        // Kalkulasi rewards yang sudah diagregasi
        this.rewards.forEach(rwd => {

            let agg = this.aggregatedRewards.find(it => it.rewardId == rwd.id)

            if(agg == undefined || agg == null) {
                
                this.aggregatedRewards.push({
                    rewardId: rwd.id,
                    name: rwd.title,
                    image: rwd.image_url,
                    quantity: 1,
                    tier: rwd.grade
                })

                this.aggregatedRewards.sort((a, b) => a.tier - b.tier)

            }
            else {
                agg.quantity++
            }

        })

    },
    beforeRouteLeave(to, from, next) {
        
        if(to.name == "Package") {
            if(this.shouldAllowBack) {
                next()
            }
            else {
                this.$bvModal.show("m-confirm-leave")
                next(false)
            }
        }
        else {
            next()
        }

    }
}
</script>

<style scoped>

    .remaining-chests {
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    .image-box {
        width: 50%;
        max-width: 200px;
    }

    .result {
        opacity: 1;
        animation: result 1s forwards;
    }

    .prep {
        animation: prep 1s infinite;
    }

    .pop {
        animation: pop 1.5s;
        animation-fill-mode: forwards;
    }

    @keyframes prep {
        0% { transform: scale(1, 1); }
        50% { transform: scale(1.1, 1.1); }
        100% { transform: scale(1, 1); }
    }

    @keyframes pop {
        5% { transform: rotate(-10deg); }
        10% { transform: rotate(10deg); }
        15% { transform: rotate(-10deg); }
        20% { transform: rotate(10deg); }
        25% { transform: rotate(-10deg); }
        30% { transform: rotate(10deg); }
        35% { transform: rotate(-10deg); }
        40% { transform: rotate(10deg); }
        45% { transform: rotate(-10deg); }
        50% { transform: rotate(10deg); opacity: 1; }
        75% { transform: scale(1.5, 1.5); opacity: 0.5; }
        99% { transform: scale(2.0, 2.0); opacity: 0; }
        100% { transform: scale(1.0, 1.0); opacity: 0; }
    }

    @keyframes result {
        0% { transform: scale(2.0, 2.0); opacity: 0 }
        100% { transform: scale(1.0, 1.0); opacity: 1; }
    }

</style>
